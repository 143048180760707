.exp-sec {
    margin-bottom: 5%;
}

.exp-header {
    font-weight: bold;
    opacity: 1;
    font-size: 20px;
}

.expmain {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
}

.period {
    font-family: 'Courier New', Courier, monospace;
    margin-right: 8%;
}

.period p {
    font-size: 14px;
}

.exp {
    flex: 1;
    margin-right: 20%;
}

.pos {
    font-weight: bold;
    opacity: 1;
    margin: 0;
}

.description {
    font-size: 14px;
    margin: 5px 0 0;
}

.skills {
    font-size: 14px;
    opacity: 1;
}

.resume-link {
    padding-top: 20px;
}

.resume-link a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    position: relative;
}

.resume-link a::after {
    content: '→';
    font-size: 16px;
    font-weight: bolder;
    margin-left: 5px;
    position: absolute;
    top: 0;
    display: inline-block;
    transform: rotate(315deg);
    transition: transform 0.3s ease;
}

.resume-link a:hover::after {
    transform: rotate(315deg) translateX(+8px);
}

@media (max-width: 800px) {
    #experiences {
        padding-left: 10%;
        padding-right: 10%;
        margin: 0%;
    }

    .exp {
        margin-right: 0%;;
    }
}
