.app {
    overflow: auto;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}
  
.LeftSide, .RightSide {
    flex: 1;
    overflow: auto;
}
  
@media (max-width: 800px) {
    .App {
        height: auto;
        width: 100%;

      }
    
      .container {
        flex-direction: column;
        height: auto;
        width: auto;
        overflow: hidden;
      }
    
      .LeftSide, .RightSide {
        height: auto;
        overflow: visible;
      }
}


  