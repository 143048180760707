a.nav {
    color: white;
    text-decoration: none;
    position: relative;
    padding-left: 40px;
    font-size: 16px;
    font-family:'Courier New', Courier, monospace;
    transition: all 0.5s ease;
    opacity: 50%;
}
  
a.nav::before {
    content: '';
    position: absolute;
    left: 0; 
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 2px; 
    background-color: white;
    transition: width 0.5s ease;
}

a.active::before,
a.nav:hover::before {
    width: 80px;
}

a.active,
a.nav:hover {
    padding-left: 90px;
    opacity: 100%;
}

.nav-sec {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-left: 28%;
}

@media (max-width: 800px) {
    .nav-sec {
        margin-left: 0%;
    }

    a.active::before,
    a.nav:hover::before {
        width: 30px;
    }

    a.active,
    a.nav:hover {
        padding-left: 40px;
        opacity: 100%;
    }
}