.left-side {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: left;
}

@media (max-width: 800px) {
    .left-side {
      width: 100%;
      height: auto;
      align-items: center;
    }
}