.footer-text {
    font-size: 14px;
    padding: 0 30% 10% 3%;
    opacity: 1;
    color: rgba(255, 255, 255, 0.5);
}

.highlight{
    color: rgba(255, 255, 255, 1); 
}

@media (max-width: 800px) {
    .footer-text {
        padding-right: 0%;
        padding-left: 0%;
    }

    .footer {
        padding-left: 10%;
        padding-right: 10%;
        margin: 0%;
    }
}