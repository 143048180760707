#profile h2 {
    font-size: 50px;
    margin-top: 15%;
    margin-bottom: 10px;
}

#profile p {
    padding-right: 30%;
    opacity: 75%;
    font-size: 17px;
    line-height: 1.8;
}

#profile h5 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#profile {
    margin-left: 28%;
    padding-bottom: 5%;
}

.skills-container {
    margin-top: 5%;
    width: 70%;
    display: flex;
    justify-content: space-between;
}

img {
    width: 22px;
    height: auto;
    opacity: 0.7;
    transform: all 0.5s ease;
}

img:hover {
    opacity: 1;
}

@media (max-width: 800px) {
    #profile {
        margin-left: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #profile p {
        padding-right: 0%;
        margin-left: 5%;
        margin-right: 5%;
    }
}