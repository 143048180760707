.pj-sec {
    padding-top: 5%;
    padding-bottom: 5%;
}

.project-image {
    padding-top: 5%;
    margin-left: 5px;
    margin-right: 25px;
    width: 103px;
    height: auto;
}

.pjmain {
    display: flex;
    align-items: self-start;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 20px;
    transition: all 0.5s ease;
    margin-right: 15%;
    margin-left: 10px;
}

.pjmain:hover {
    box-shadow: 0 0 0 2px #4b6179;
    background-color: rgba(98, 122, 154, 0.1);
    border-radius: 5px;
}

.pjmain .title::after {
    content: '→';
    font-size: 16px;
    font-weight: bolder;
    margin-left: 5px;
    display: inline-block;
    transform: rotate(315deg);
    transition: transform 0.3s ease;
}

.pjmain:hover .title::after {
    transform: rotate(315deg) translateX(+8px);
}

.title {
    opacity: 1;
    font-weight: bold;
    margin: 0;
}

.description {
    font-size: 14px;
    margin: 5px 0 0;
}

.project {
    flex: 1;
}

.tech {
    opacity: 1;
    font-size: 14px;
}

a {
    text-decoration: none;
    color: inherit;
}

.live-sec p {
    font-size: 14px;
}

#ongoing-pj {
    animation: flash 2s infinite;
}

@keyframes flash {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}

@media (max-width: 800px) {
    #projects {
        padding-left: 10%;
        padding-right: 10%;
        margin: 0%;
    }

    .pjmain {
        margin-right: 0%;
    }
}