.links img {
    width: 25px;
    height: auto;
    opacity: 0.7;
    transform: all 0.5s ease;
}

.links img:hover {
    opacity: 1;
}

.links {
    margin-top: 12%;
    width: 30%;
    margin-left: 28%;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 800px) {
    .links {
        margin-left: 0%;
    }
}